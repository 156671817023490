import React, {useState} from "react";
import PhoneInput from "react-phone-input-2";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai"
import { CircularProgress, Alert } from "@mui/material";
import { baseUrlDev } from "../Helpers/baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AccreditationSignupInformation = ({active, setActive, company}) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [hidden, setHidden] = useState(true)
    const [errors, setErrors] = useState([])
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        password: ""
    })
    const isEmptyElementPresent = Object.values(data).some((element) => {
        return element === '';
    });

    const completeAccreditation = () => {
        let postData = Object.assign(data, {place_id: company})
        setLoading(true)
        axios.post(`${baseUrlDev}accreditation`, postData, {headers:{'APP-VERSION': 'react' }})
        .then(res => {
            setLoading(false)
            localStorage.setItem('user', JSON.stringify(res.data));
            setTimeout(() => {
                window.location.href = res.data.link
            }, 500);
        })
        .catch(err => {
            setLoading(false)
            err.response.data.errors ?
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            :
            setErrors(["Something Went Wrong"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })
    }

    return(
      <>
        <div className="extra-information-wrapper">
            {errors && errors.map(err => {
                return (
                    <div className="alert-inside-modal" key={err}>
                        <Alert severity="error" className="custom-error-alert">{err}</Alert>
                    </div>
                )
            })}
            <h6>Complete Accreditation Process</h6>
            <div>
                <input type="text" placeholder="First Name" value={data.first_name} onChange={(e) => setData({...data, first_name: e.target.value})}/>
                <input type="text" placeholder="Last Name" value={data.last_name} onChange={(e) => setData({...data, last_name: e.target.value})}/>
                <input type="text" placeholder="Email" value={data.email} onChange={(e) => setData({...data, email: e.target.value})}/>
                <div className="input-wrapper-acc">
                    <PhoneInput
                        enableSearch
                        value={data.phone}
                        specialLabel= ""
                        onChange={(val) => setData({...data, "phone": val})}
                        required
                        country={"us"}
                        preferredCountries={['us', 'ca']}
                    /> 
                </div> 
                <div className="position-relative">
                    <input type={hidden ? "password" :  "text"} placeholder="Password" value={data.password} onChange={(e) => setData({...data, password: e.target.value})}/>
                    {!hidden && <AiOutlineEye onClick={() => setHidden(true)}/>}
                    {hidden && <AiOutlineEyeInvisible onClick={() => setHidden(false)}/>}
                </div>
            </div>
            <div className="accreditation-form-btn">
                <button disabled={isEmptyElementPresent} className="d-flex align-items-center justify-content-center" onClick={() => completeAccreditation()}>
                    {loading && 
                        <CircularProgress 
                            style={{color: "#fff", width: "15px", height: "15px", margin: "0 10px"}}
                        />
                    }
                    Complete
                </button>
            </div>
        </div>
      </>
    
    )
}
export default AccreditationSignupInformation