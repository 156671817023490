import React, {useEffect, useState }from "react"
import { Collapse, Skeleton } from "@mui/material"
import PhoneInput from 'react-phone-input-2';
import { fetchCompaniesGoogle, clearCatAndComp } from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux"
import axios from "axios";
import { baseUrlDev } from "../Helpers/baseUrl";

let controller = new AbortController();
let controllerLead = new AbortController();

const SignupForm = ({errors, fields, setFields, showFields, setShowFields, setErrors}) => {
    const [valid, setValid] = useState(false)
    const [search, setSearch] = useState(false)
    const dispatch = useDispatch()

    const companies = useSelector(state => state.companiesSearchReducer.companies)

    useEffect(() => {
       if(valid){
            let data = {
                from: "From sign up page",
                first_name: fields.first_name,
                last_name: fields.last_name,
                email: fields.email,
                phone: fields.phone,
                place_id: fields.place_id,
                business_name: fields.business_name
            }

            controllerLead.abort();
            controllerLead = new AbortController()

            axios.post(`${baseUrlDev}lead`, data, {signal: controllerLead.signal, headers:{'APP-VERSION': 'react' }})
       }
    }, [fields, valid])


    const checkEmailValid = (email) => {
        controller.abort();
        controller = new AbortController()

        axios.post(`${baseUrlDev}register/email/check`, {email}, {signal: controller.signal, headers:{'APP-VERSION': 'react' }})
        .then(res => {
            let valid_email = res.data.data.valid_email
            let exist_user_email = res.data.data.exist_user_email
            console.log(exist_user_email)
            if(!valid_email){
                setErrors([...errors, {email: "This email is not valid"}])
                setValid(false)
            } else if (exist_user_email){
                setErrors([...errors, {email: "This email exist in our system"}])
            }

            if(valid_email){
                setValid(true)
            }
        })
        .catch(err => console.log(err))
 
    }

    const checkBusinessValid = (place_id) => {
        axios.post(`${baseUrlDev}register/location/check`, {place_id: place_id}, {headers:{'APP-VERSION': 'react' }})
        .then(res => {
            let location_exist = res.data.data.exist_location
            if(location_exist){
                setErrors([...errors, {business_name: "We have account with this location"}])
                dispatch(clearCatAndComp())
            }
        })
        .catch(err => console.log(err))
 
    }


    const handleFieldChange = (e) => {
        const {name, value, valid} = e.target
       
        setFields({...fields, [name]: value})
        setErrors(errors.map(el => el[name]).filter(email => email))

        if(name === "email"){
            setValid(false)
        }

        if(name === "email" && e.target.checkValidity()){
            if(!showFields.includes("business_name")){
                setShowFields(prev => [...prev, "business_name"])
            }
            checkEmailValid(value)

        } else if( name === "business_name" && e.target.checkValidity()){
            if(!showFields.includes("phone")){
                setShowFields(prev => [...prev, "phone"])
            }
           
        } else if( name === "phone" && value.length == 11){
            let leftItems = ["first_name", "last_name", "password"]

            leftItems.map(el => {
                if(!showFields.includes(el)){
                    setShowFields(prev => [...prev, el])
                }
            })
        } 

    }
    const handleSearch = (e) => {
        dispatch(clearCatAndComp())
        let value = e.target.value;
        if(value.length >= 3){
            dispatch(fetchCompaniesGoogle(value))
            setSearch(true)
        } else {
            setSearch(false)
        }
    }
    const handleCompanyDetails = (company) => {
       checkBusinessValid(company.place_id)
       setFields({...fields, "business_name": company.description, "place_id": company.place_id})
       dispatch(clearCatAndComp())
       setSearch(false)
    }

    return(
        <div>
            <Collapse in={showFields.includes("email")}>
                <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.email)?.filter(email => email)[0] && "error"}`}>
                    <input 
                        required 
                        type="email" 
                        name="email" 
                        value={fields.email} 
                        placeholder="email" 
                        onChange={(e) => handleFieldChange(e)}
                        pattern={'^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$'}
                    />
                    {errors.length > 0 && errors.map(el => el.email)?.filter(email => email)[0] && <span>{errors.map(el => el.email).filter(email => email)[0]}</span>}
                </div>
            </Collapse>
            <Collapse in={showFields.includes("business_name")}>
               <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.business_name)?.filter(business_name => business_name)[0] && "error"}`}>
                    <input required type="text" name="business_name" value={fields.business_name} placeholder="Business Name" onChange={(e) => {
                        handleFieldChange(e)
                        handleSearch(e)
                    }}/>
                    {errors.length > 0 && errors.map(el => el.business_name)?.filter(business_name => business_name)[0] && <span>An account with this location already exist.</span>}
               </div>
                <Collapse in={search}>
                    <div className="autocomplete-results-sp custom-scrollbar-vertical" >
                        {companies === undefined ? 
                        <>
                            <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/> 
                            <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                            <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                            <Skeleton height={35} width={"100%"} style={{marginBottom: "15px", transform: "unset"}}/>
                        </>
                        : 
                        companies.map(company => {
                            return(
                                <div className="autocomplete-results-item-sp" key={company.place_id} onClick={(e) => handleCompanyDetails(company)}>
                                    <h4>{company.description}</h4>
                                </div>
                            )
                        })}
                    </div>
                </Collapse>
            </Collapse>
            <Collapse in={showFields.includes("phone")}>
                <div className="inputs-wrapper-sp">
                    <PhoneInput
                        enableSearch
                        value={fields.phone}
                        specialLabel= ""
                        onChange={(val) => handleFieldChange({ target: { name: "phone", value: val } })}
                        required
                        country={"us"}
                        preferredCountries={['us', 'ca']}
                    /> 
                </div>                                   
            </Collapse>
            <Collapse in={showFields.includes("first_name")}>
                <div className="inputs-wrapper-sp">
                    <input type="text" name="first_name"  value={fields.first_name} placeholder="First Name"  onChange={(e) => handleFieldChange(e)}/>
                </div>
            </Collapse>
            <Collapse in={showFields.includes("last_name")}>
                <div className="inputs-wrapper-sp">
                    <input type="text" name="last_name" value={fields.last_name} placeholder="Last Name" onChange={(e) => handleFieldChange(e)}/>
                </div>
            </Collapse>
            <Collapse in={showFields.includes("password")}>
                <div className={`inputs-wrapper-sp ${errors.length > 0 && errors.map(el => el.password)?.filter(password => password)[0] && "error"}`}>
                    <input type="password" minLength="7" name="password" value={fields.password} placeholder="Enter Password" onChange={(e) => handleFieldChange(e)}/>
                    {errors.length > 0 && errors.map(el => el.password)?.filter(password => password)[0] && <span>{errors.map(el => el.password).filter(password => password)[0]}</span>}
                </div>
            </Collapse>
        </div>
    )
}
export default SignupForm